* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 2px solid red; */
  min-height: 100vh;
}
.header {
  /* border: 2px solid red; */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  background-color: #fdf9f0;
}
.footer {
  /* position: fixed;
  bottom: 0;
  z-index: 5; */
  background-color: black;
  width: 100%;
}
.main {
  /* margin-top: 20px; */
  /* border: 2px solid red; */
  min-height: 100dvh;
  margin-top: 120px;
}
.whatsApp {
  /* border: 1px solid red; */
  border-radius: 50%;
  background-color: rgb(62, 182, 62);
  padding: 8px;
  width: 55px;
  height: 55px;
  position: fixed;
  z-index: 5;
  bottom: 8%;
  right: 5%;
  cursor: pointer;
}
.whatsApp img {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
