* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: calc(100% - 20px);
  /* margin: auto; */
  /* border: 1px solid gray; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.imgSection img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.name {
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 700;
  /* border: 1px solid red; */
  padding: 3px 5px 5px 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media screen and (min-width: 768px) {
  .container {
    width: calc(50% - 10px);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 20%;
  }
}
