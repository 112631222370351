* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 2px solid green; */
  width: 99%;
  min-height: 100vh;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 100px;
}
.banner {
  width: 100%;
  position: relative;
  display: block;
}
.banner img {
  width: 100%;
  object-fit: contain;
}
.type,
.route {
  position: absolute;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 800;
  text-transform: capitalize;
  color: white;
  letter-spacing: 1px;
  top: 10%;
  left: 5%;
  width: fit-content;
}
.route {
  top: 40%;
  font-size: 0.8rem;
}
.searchContainer {
  /* border: 1px solid red; */
  padding: 5px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}
.inputBox {
  flex-grow: 1;
}
.inputBox input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1.1rem;
  padding: 8px 12px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.searchIcon {
  padding: 8px 12px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.caption {
  margin-top: 20px;
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}
.line {
  width: 20%;
  margin: auto;
  border: 2px solid rgba(38, 252, 0, 0.668);
}
.info {
  width: 90%;
  margin: auto;
  font-family: Poppins;
  /* font-weight: 600; */
  text-align: justify;
  font-size: 1rem;
}
.info > div {
  margin-top: 20px;
}
.productsCont {
  /* border: 1px solid red; */
  padding-top: 35px;
  padding: 10px 0px;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2px;
  row-gap: 20px;
}

@media screen and (min-width: 426px) {
  .type {
    font-size: 2.6rem;
  }
  .route {
    top: 60%;
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .banner {
    width: 99%;
    margin: auto;
  }
  .banner img {
    width: 100%;
  }
  .route {
    top: 50%;
  }
  .info {
    font-size: 1rem;
  }
  .caption {
    font-size: 2rem;
  }
}
