* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  border: 2px solid white;
  min-height: 100dvh;
  padding-bottom: 100px;
}
.banner {
  margin-top: 20px;
}
.banner img {
  width: 100%;
  object-fit: contain;
}
.caption {
  text-align: center;
  margin-top: 20px;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 600;
}
.about,
.gmd,
.specification,
.ingrediants,
.uses,
.last {
  /* border: 1px solid red; */
  width: 95%;
  margin: auto;
  margin-top: 25px;
  font-family: Poppins;
  /* font-weight: 600; */
  text-align: justify;
  padding: 0px 10px;
  font-size: 1rem;
}
table,
th,
td {
  border: 1px solid green;
  border-collapse: collapse;
}
table {
  width: 100%;
}
th,
td {
  padding: 10px 15px;
  font-size: 0.8rem;
  text-transform: capitalize;
}
th {
  font-family: "Courier New", Courier, monospace;
}
.gmdCaption,
.specCaption,
.ingCaption,
.usesCaption {
  font-family: Poppins;
  font-weight: bolder;
  text-transform: bold;
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.innerNav {
  display: none;
}
@media screen and (min-width: 769px) {
  table {
    width: 55%;
  }
  td,
  th {
    font-size: 1rem;
  }
  .innerNav {
    display: flex;
    /* border: 1px solid red; */
    padding: 15px;
    margin: 100px 0px;
    justify-content: center;
    gap: 30px;
    position: sticky;
    top: 100px;
    background-color: white;
    z-index: 4;
  }
  a {
    text-decoration: none;
    color: green;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 800;
  }
  .innerNav > div {
    color: green;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 800;
    border: 1px dashed green;
    padding: 10px 15px;
    cursor: pointer;
  }
}
