* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 3px solid red; */
  width: 100%;
  height: 600px;
  margin: auto;
  position: relative;
  /* overflow: ; */
}
.imgCont {
  display: flex;
  /* border: 2px solid red; */
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.imgCont img {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 100%;
  /* scale: 0.9; */
}

.leftArrowCont {
  /* border: 1px solid red; */
  height: 100%;
  width: 15%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  cursor: pointer;
}
.leftArrowCont:hover .leftArrow {
  border-left-color: white;
  border-top-color: white;
}
.leftArrow {
  width: 20px;
  height: 20px;
  border-left: 3px solid rgb(167, 163, 163);
  border-top: 3px solid rgb(167, 163, 163);
  position: absolute;
  left: 40%;
  top: 40%;
  transform: rotate(-45deg);
}
.rightArrowCont {
  /* border: 1px solid red; */
  height: 100%;
  width: 15%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  cursor: pointer;
}
.rightArrowCont:hover .rightArrow {
  border-right-color: white;
  border-top-color: white;
}
.rightArrow {
  width: 20px;
  height: 20px;
  border-right: 3px solid rgb(167, 163, 163);
  border-top: 3px solid rgb(167, 163, 163);
  position: absolute;
  left: 40%;
  top: 40%;
  transform: rotate(45deg);
}
.nav {
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 40%;
  /* border: 1px solid red; */
  width: 20%;
  justify-content: space-between;
  gap: 15px;
}
.nav > div {
  border: 1px solid rgb(166, 158, 158);
  cursor: pointer;
  flex-grow: 1;
}
.nav > div:hover {
  border-color: white;
}
.carText {
  /* border: 1px solid red; */
  position: absolute;
  top: 20%;
  left: 3.5%;
  /* margin-left: auto; */
  margin-right: auto;
  /* width: 50%; */
  /* text-align: center; */
}
.carText > div {
  /* border: 1px solid red; */
  text-align: center;
  color: white;
  font-family: Poppins;
  width: 100%;
  /* margin: auto; */
}
.carText > div:first-child {
  font-size: 3rem;
  font-weight: 800;
}
.carText > div:nth-child(2) {
  font-size: 2rem;
}
/* .carText > div:last-child {
  width: 50%;
  margin: auto;
  margin-top: 10px;
  padding: 5px 15px;
  background-color: #3ec1d5;
  border-radius: 15px;
  cursor: pointer;
} */

@media screen and (min-width: 769px) {
  .container {
    height: 550px;
  }
  .leftArrowCont,
  .rightArrowCont {
    width: 10%;
  }
  .carText {
    left: 10%;
  }
  .carText > div:nth-child(2) {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1025px) {
  .carText {
    left: 22%;
  }
}
