* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 2px solid red; */
  padding-top: 50px;
}
.caption {
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 800;
  padding-left: 20px;
}
.btnCont {
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  margin-top: 20px;
}
.btnCont button {
  width: 100%;
  background-color: greenyellow;
  color: white;
  border: none;
  font-size: 1rem;
  padding: 10px;
  font-family: Poppins;
  border-radius: 12px;
  font-weight: 800;
  cursor: pointer;
}
@media screen and (min-width: 426px) {
  .caption {
    text-align: center;
    padding-left: 0;
  }
  .btnCont {
    width: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .btnCont {
    width: 30%;
  }
}
