* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 3px solid red; */
  width: 100%;
  height: 350px;
  margin: auto;
  position: relative;
  background-color: #ffea90;
  display: flex;
  align-items: center;
  /* overflow: ; */
}
.imgCont {
  width: 70%;
  margin: auto;
  display: flex;
  overflow: scroll;
  height: 80%;
  gap: 35px;
  /* border: 3px solid red; */
  padding: auto 20px;
}
.imgCont > div {
  width: 100%;
  /* display: flex; */
  flex-grow: 0;
  flex-shrink: 0;
  gap: 35px;
}
/* .imgCard {
  position: relative;
} */
.imgCard > div:first-child img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.imgName {
  /* position: absolute; */
  text-align: center;
  font-family: Poppins;
  font-weight: 800;
  font-size: 1rem;
  text-transform: capitalize;
}

.imgCont::-webkit-scrollbar {
  display: none;
}
.leftArrowCont {
  /* border: 1px solid red; */
  height: 100%;
  width: 15%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.2); */
}
.leftArrowCont:hover .leftArrow {
  border-left-color: white;
  border-top-color: white;
}
.leftArrow {
  width: 20px;
  height: 20px;
  border-left: 3px solid rgb(167, 163, 163);
  border-top: 3px solid rgb(167, 163, 163);
  position: absolute;
  left: 40%;
  top: 40%;
  transform: rotate(-45deg);
}
.rightArrowCont {
  /* border: 1px solid red; */
  height: 100%;
  width: 15%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.2); */
}
.rightArrowCont:hover .rightArrow {
  border-right-color: white;
  border-top-color: white;
}
.rightArrow {
  width: 20px;
  height: 20px;
  border-right: 3px solid rgb(167, 163, 163);
  border-top: 3px solid rgb(167, 163, 163);
  position: absolute;
  left: 40%;
  top: 40%;
  transform: rotate(45deg);
}

@media screen and (min-width: 768px) {
  .imgCont > div {
    width: 33%;
    /* display: flex; */
    flex-grow: 0;
    flex-shrink: 0;
    gap: 35px;
  }
  .imgCard > div:first-child img {
    width: 100%;
    height: 100%;
  }
  .imgName {
    text-align: center;
    font-family: Poppins;
    font-weight: 800;
    font-size: 1rem;
    text-transform: capitalize;
    padding-top: 10px;
  }
}
