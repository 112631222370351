* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 2px solid red; */
  min-height: 100dvh;
  padding-bottom: 100px;
}
.container > div {
  height: 500px;
  /* border: 2px solid red; */
}
iframe {
  width: 90%;
  height: 90%;
  display: block;
  overflow: scroll;
  margin: auto;
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .container {
    display: flex;
  }
  .container > div {
    width: 70%;
    height: 800px;
  }
}
