* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  /* border: 2px solid red; */
  padding: 50px 0px;
  background-color: rgb(44, 41, 41);
}

.links {
  padding-left: 20px;
}
.link {
  text-decoration: none;
  color: rgb(146, 140, 140);
  font-family: Poppins;
  font-size: 1.1rem;
  /* all: inset; */
}
.link:hover {
  color: rgb(175, 170, 170);
}
.caption {
  color: rgb(255, 255, 255);
  font-family: Poppins;
  font-size: 1.5rem;
  padding-left: 10px;
}
.address {
  margin-top: 20px;
}
.upper > div {
  /* border: 1px solid red; */
  width: 95%;
  margin: auto;
}
.address > div:not(:first-child),
.mail > div:nth-child(2),
.number > div:nth-child(2) {
  color: rgb(146, 140, 140);
  font-family: Poppins;
  padding-left: 10px;
}

@media screen and (min-width: 426px) {
  .upper > div {
    /* border: 1px solid red; */
    width: 80%;
    margin: auto;
  }
}
@media screen and (min-width: 768px) {
  .caption {
    font-size: 1.1rem;
  }
  .upper {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0px 20px;
  }

  .upper > div {
    /* border: 1px solid red; */
    width: 20%;
    margin: 0;
  }
  .upper > div > div {
    word-wrap: break-word;
  }
}
